import { useState } from "react";

import Text from "ab-text";

import "./ab-paragraph-text.scss";

const ParagraphText = ({
  text = [],
  singleColumn,
  className,
  content,
  noPlus,
}) => {
  const [viewMore, setViewMore] = useState(false);
  // console.log("ParagraphText", content, text);
  var parsedHtml = content
    ? content

        ?.replace(/<div>/g, "<p>")
        ?.replace(/<\/div>/g, "</p>")
        ?.replace(/<div class/g, "<p class")
        ?.replace(/<ul>/g, "<p><ul>")
        ?.replace(/<\/ul>/g, "</ul></p>")
        ?.replace(/<p>/g, "")
        ?.replace(/\n/g, "")
        ?.replace(/\r/g, "")
        ?.split("</p>")
        ?.filter((e) => e)
    : [];

  // console.log("ParagraphText - content", content);
  // console.log("ParagraphText - parsedHtml", parsedHtml);
  // console.log("ParagraphText - text", text);
  if (text.length == 0 && parsedHtml.length == 0) {
    return null;
  }
  return (
    <div
      className={`ab-paragraph-text ${className || ""} ${
        viewMore ? "full" : ""
      }`}
      style={{
        // height: maxHeight,
        columnCount: singleColumn ? undefined : 2,
        display: singleColumn ? "flex" : "block",
        columnGap: singleColumn ? undefined : 36,
        columnFill: singleColumn ? undefined : "balance",
      }}
    >
      {parsedHtml?.map((itm, index) => {
        if (!itm) {
          return null;
        }
        if (noPlus) {
          return (
            <Text key={`paragraph-text-${index}`} className={"no-plus show"}>
              {itm}
            </Text>
          );
        } else if (index == 0 || parsedHtml.length == 1) {
          return (
            <Text
              key={`paragraph-text-${index}`}
              onClick={
                viewMore
                  ? parsedHtml.length == 1
                    ? () => setViewMore(!viewMore)
                    : null
                  : () => setViewMore(!viewMore)
              }
              className={
                viewMore || parsedHtml.length == 1
                  ? "no-plus"
                  : index == 0
                  ? "plus"
                  : undefined
              }
            >
              {itm}
            </Text>
          );
        } else if (parsedHtml.length > 1 && index == parsedHtml.length - 1) {
          console.log("item", itm)
          if(itm.startsWith('<')){
            return <div  dangerouslySetInnerHTML={{__html: itm}}/>
          }else{
            return (
              <Text
                key={`paragraph-text-${index}`}
                onClick={viewMore ? () => setViewMore(!viewMore) : null}
                id={viewMore ? "less" : undefined}
                className={viewMore && itm?.includes("<ul>") ? "less" : ""}
                >
                {itm}
              </Text>
            );
          }
        }

        return <Text key={`paragraph-text-${index}`}>{itm}</Text>;
      })}
      {text
        ?.filter((e) => e)
        .map((itm, index) => {
          if (index == 0 || text.length == 1) {
            return (
              <Text
                key={`paragraph-text-${index}`}
                onClick={
                  viewMore
                    ? text.length == 1
                      ? () => setViewMore(!viewMore)
                      : null
                    : () => setViewMore(!viewMore)
                }
                className={
                  viewMore || text.length == 1
                    ? "no-plus"
                    : index == 0
                    ? "plus"
                    : undefined
                }
                id={viewMore ? "less" : undefined}
              >
                {itm}
              </Text>
            );
          } else if (text.length > 1 && index == text.length - 1) {
            return (
              <Text
                key={`paragraph-text-${index}`}
                onClick={viewMore ? () => setViewMore(!viewMore) : null}
                id={viewMore ? "less" : undefined}
              >
                {itm}
              </Text>
            );
          }

          return <Text key={`paragraph-text-${index}`}>{itm}</Text>;
        })}
    </div>
  );
};

export default ParagraphText;
