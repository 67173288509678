import Image from "next/image";
import Text from "ab-text";

import "./services-card.scss";

const ServicesCard = ({ src, srcSet, label, href, two, link }) => {
  console.log("servicesCard", src, srcSet);
  return (
    <a className="services-card" href={href || link?.url}>
      <div className="image">
        <Image src={src || '/images/404.png'} layout={"fill"} alt={'label'} />
      </div>

      <Text element="span" className="tile">
        {label}
      </Text>
    </a>
  );
};

export default ServicesCard;
