import Image from "next/image";
import Link from "next/link";

import Text from "ab-text";

import "./cross-selling-card.scss";

const CrossSellingCard = ({ src, srcSet, label, title, href, link }) => {
  return (
    <Link href={href || link?.url}>
      <a className={`cross-selling-card`}>
        <div className="image">
          <Image src={src || '/images/404.png'} layout={"fill"} alt={label} />
        </div>

        <Text element="span" className="titles">
          {title}
        </Text>

        <Text element="span" className="title">
          {label}
        </Text>
      </a>
    </Link>
  );
};

export default CrossSellingCard;
